import { MuiTypography } from '@/components/mui/Typography';
import { cx } from '@/themes/utils/cssUtils';
import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface BaseLabelProps
  extends PropsWithChildren,
    HTMLAttributes<HTMLParagraphElement> {
  required?: boolean;
}

export function BaseLabel({
  required = undefined,
  children,
  className,
  ...props
}: BaseLabelProps) {
  if (!children) return null;

  let requiredHelperText: ReactNode = '';

  if (required) {
    requiredHelperText = <span className="text-wx-danger-dark">*</span>;
  }

  if (required === false) {
    requiredHelperText = (
      <span className="text-wx-neutral-500">
        (<FormattedMessage defaultMessage="optional" />)
      </span>
    );
  }

  return (
    <MuiTypography
      className={cx('text-base font-medium text-wx-neutral-600', className)}
      {...props}
    >
      {children} {requiredHelperText}
    </MuiTypography>
  );
}
